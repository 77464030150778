<template>
	<div class="c-board-select">
		<div class="c-board-select__header" v-if="currentBoards.length > 10">
			<InputText v-model="query" placeholder="Search for a board"></InputText>
		</div>
		<div class="c-board-select__inner">
			<draggable v-bind="dragOptions" tag="div" v-model="currentBoards" handle=".c-board-select__handle" @end="onEnd" @start="drag = true">
				<transition-group type="transition" :name="!drag ? 'flip-list' : null">
					<router-link class="c-board-select__item" @click.native="onClick" :to="`/boards/${board.slug}`" v-for="board in computedBoards" :key="board.slug">
						<svg v-if="!query" class="c-board-select__handle" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M3 6H21M3 12H21M3 18H21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
						</svg>
						<span v-else class="blank-icon"></span>

						<div :class="['c-board-select__counter', { active: board._count > 0 }]">
							{{ board._count || 0 }}
						</div>
						<span> {{ board.name }}</span>
						<svg @click.prevent="updateBoard(board)" class="muted" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M13.5 6L15.5858 3.91422C16.3668 3.13317 17.6332 3.13317 18.4142 3.91422L20.0858 5.58579C20.8668 6.36684 20.8668 7.63317 20.0858 8.41422L18 10.5M13.5 6L3.29289 16.2071C3.10536 16.3946 3 16.649 3 16.9142V21H7.08579C7.351 21 7.60536 20.8946 7.79289 20.7071L18 10.5M13.5 6L18 10.5"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</router-link>
				</transition-group>
			</draggable>
			<article class="c-board-select__empty-state" v-if="currentBoards.length === 0">
				<p>No boards created.</p>
				<span> Organize your Ads by creating boards. </span>
			</article>
		</div>
		<!-- <div class="c-board-select__inner">
			<div class="c-board-select__item">Project one</div>
		</div> -->
		<!-- <div class="c-board-select__add">
			<a class="c-board-select__item" @click.prevent="addBoard" href="#">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM12.75 7C12.75 6.58579 12.4142 6.25 12 6.25C11.5858 6.25 11.25 6.58579 11.25 7V11.25H7C6.58579 11.25 6.25 11.5858 6.25 12C6.25 12.4142 6.58579 12.75 7 12.75H11.25V17C11.25 17.4142 11.5858 17.75 12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V12.75H17C17.4142 12.75 17.75 12.4142 17.75 12C17.75 11.5858 17.4142 11.25 17 11.25H12.75V7Z"
						fill="currentColor"
					/>
				</svg>
				<span> Add Board </span>
			</a>
			<a class="c-board-select__item" @click.prevent="uploadAd" href="#">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM12.75 7C12.75 6.58579 12.4142 6.25 12 6.25C11.5858 6.25 11.25 6.58579 11.25 7V11.25H7C6.58579 11.25 6.25 11.5858 6.25 12C6.25 12.4142 6.58579 12.75 7 12.75H11.25V17C11.25 17.4142 11.5858 17.75 12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V12.75H17C17.4142 12.75 17.75 12.4142 17.75 12C17.75 11.5858 17.4142 11.25 17 11.25H12.75V7Z"
						fill="currentColor"
					/>
				</svg>
				<span> Upload Ad </span>
			</a>
		</div> -->
	</div>
</template>

<script>
import draggable from 'vuedraggable';

import api from '@swipekit/lib/api';

import InputText from '@swipekit/components/form/input-text';

import Fuse from 'fuse.js';

export default {
	components: {
		draggable,
		InputText,
	},

	data: function () {
		return {
			query: '',
			currentBoards: [],

			drag: false,
		};
	},

	watch: {
		boards: {
			immediate: true,
			handler: function () {
				this.currentBoards = this.boards;
			},
		},
	},

	computed: {
		dragOptions() {
			return {
				animation: 200,
				group: 'description',
				disabled: false,
				ghostClass: 'ghost',
			};
		},
		computedBoards: function () {
			let boards = this.currentBoards;

			boards = JSON.parse(JSON.stringify(boards));

			if (this.query) {
				let options = {
					keys: ['name'],
				};
				let fuse = new Fuse(boards, options);
				let items = fuse.search(this.query);
				items = items.map((i) => {
					return i.item;
				});
				return items;
			}

			return boards;
		},
		init: function () {
			return this.$store.getters['app/init'];
		},
		boards: function () {
			let boards = this.$store.getters['boards/filtered'];

			return boards;
		},
	},

	methods: {
		onClick: function () {
			this.$store.dispatch('app/toggleSidebar');
		},
		onEnd: function () {
			this.drag = false;
			this.setOrder();
		},
		setOrder: function () {
			let newArr = [];
			this.currentBoards.map((b, i) => {
				newArr.push({
					id: b.id,
					order: i,
				});
			});

			const obj = {
				boards: newArr,
			};

			api.boards.setOrder(obj);
		},
		updateBoard: function (board) {
			this.$store.dispatch('boards/setEdit', board.id);
		},
	},
};
</script>

<style lang="scss">
.c-board-select {
	height: 100%;

	.flip-list-move {
		transition: transform 250ms;
	}

	.no-move {
		transition: transform 0s;
	}

	.sortable-ghost {
		opacity: 0.5;
		//background: #c8ebfb;
	}

	&__header {
		padding: var(--margin);

		.form-group {
			padding-bottom: 0;
		}
	}

	&__inner {
		height: calc(100% - 82px);
		overflow-y: auto;
	}

	&__counter {
		width: 24px;
		height: 24px;
		min-width: 24px;
		margin-right: var(--margin);
		background-color: var(--color-font);
		color: #fff;
		border-radius: 99px;
		text-align: center;
		line-height: 1.8;
		font-size: var(--font-size-xs);
	}

	&__item {
		position: relative;
		display: flex;
		align-items: center;
		padding: var(--margin);
		font-size: var(--font-size-sm);
		text-decoration: none !important;
		border-radius: 10px;
		color: var(--color-font);
		transition: all 120ms ease-out;

		// &:after {
		//   content: "";
		//   position: absolute;
		//   bottom: 0;
		//   left: 0;
		//   height: 4px;
		//   width: 100%;
		//   border-radius: 99px;
		//   background-color: var(--color-primary);
		//   opacity: 0;
		// }

		// &.sortable-chosen {
		//   &:after {
		//     opacity: 1;
		//   }
		// }

		span {
			word-break: break-word;
		}

		svg {
			min-width: 24px;
			opacity: 0.55;

			&:hover {
				opacity: 1;
			}
		}

		svg:first-of-type {
			margin-right: var(--margin);
			cursor: grab;
		}

		svg:last-of-type {
			margin-left: auto;
		}

		&:hover,
		&:active,
		&.router-link-active {
			background-color: var(--color-primary);
			color: var(--color-bg-1);
		}

		// &.sortable-chosen {
		//   &:after {
		//     opacity: 1;
		//   }
		// }
	}

	&__add {
		padding-top: var(--margin);
		margin-top: var(--margin);
		border-top: hsl(var(--p), 8%, 42%) solid 1px;

		.c-board-select__item {
			svg:last-of-type {
				margin-left: initial;
			}
		}
	}

	&__empty-state {
		text-align: center;
		padding: var(--spacer);

		> p {
			opacity: 0.75;
			margin-bottom: var(--margin);
		}
	}
}
</style>
