<template>
	<div class="c-modal-ad-create">
		<Modal v-model="options.active" @input="closeModal" :closable="true">
			<template v-slot:title>
				<h3>Upload Ad</h3>
				<article class="article-text-sm">
					<p>Upload jpegs, mp4s, etc. from your device.</p>
					<p>They will be uploaded to your Swipekit account.<br />They won't show up in Multiplayer.</p>
				</article>
			</template>
			<template>
				<Upload></Upload>
			</template>
		</Modal>
	</div>
</template>

<script>
import Modal from './index.vue';
import FormBoardCreate from '@swipekit/components/form/form-board-create.vue';
import Upload from '@swipekit/components/upload';
export default {
	components: {
		Modal,
		FormBoardCreate,
		Upload,
	},

	data: function () {
		return {
			options: {
				name: 'modal-tracker-create',
				active: false,
			},
		};
	},

	watch: {
		'options.active': {
			handler: function () {},
		},
		addId: function () {
			if (this.addId) {
				this.options.active = true;
			} else {
				this.options.active = false;
			}
		},
	},

	computed: {
		addId: function () {
			return this.$store.getters['ads/addId'];
		},
	},

	methods: {
		onSubmit: function () {
			this.$store.dispatch('triggerExtension', { type: 'boards:update' });
			this.$store.dispatch('message/onBoardCreate');
			this.options.active = false;
		},
		activate: function () {
			this.options.active = true;
		},
		closeModal: function () {
			this.$store.dispatch('ads/setAdd', null);
		},
	},

	mounted: function () {
		//this.options.active = true;
	},
};
</script>

<style lang="scss">
.c-modal-ad-create {
	.article-text-sm {
		text-align: left;
	}
}
</style>
