<template>
  <div class="c-renderer">
    <component :shared="shared" :is="type" :block="block" :single="single" @onDownload="onDownload" :hideDownload="hideDownload"> </component>
  </div>
</template>

<script>
import Facebook from './facebook.vue';
import Tiktok from './tiktok.vue';
import Linkedin from './linkedin.vue';
import Pinterest from './pinterest.vue';
import Google from './google.vue';
import UploadCustom from './upload-custom.vue';

import Webpage from './webpage.vue';
import WebpageSelection from './webpage-selection.vue';

export default {
  components: {
    Facebook,
    Tiktok,
    Linkedin,
    Pinterest,
    Google,
    Webpage,
    WebpageSelection,
    UploadCustom,
  },

  props: {
    block: {},
    single: {
      type: Boolean,
      default: false,
    },
    standalone: {
      type: Boolean,
      default: false,
    },
    multiplayer: {
      type: Boolean,
      default: false,
    },
    shared: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hideDownload: function () {
      if (this.shared) {
        return true;
      }
      if (this.multiplayer) {
        return true;
      }
      if (this.standalone) {
        return true;
      }

      return false;
    },
    type: function () {
      let type = this.block.type;

      if (type === 'tiktok-top-ads') {
        return 'tiktok';
      }
      if (type === 'tiktok-library') {
        return 'tiktok';
      }

      return type;
    },
    assetUrl: function () {
      return this.$store.getters['config/assetUrl'];
    },
    workspace: function () {
      return this.$store.getters['workspaces/all'];
    },
  },

  methods: {
    onDownload: function (url, fileName) {
      if (!this.workspace.activated) {
        this.$store.dispatch('message/onPushEmailVerify');
        return;
      }
      this.$store.dispatch('message/onDownloadStarted');
      let assetUrl = this.assetUrl;
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        if (!this.response.size && this.response.size === 0) {
          window.open(`${assetUrl}/${fileName}`, '_blank').focus();
          return;
        }
        if (this.response.size < 60) {
          window.open(`${assetUrl}/${fileName}`, '_blank').focus();
        }
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement('a');
        tag.href = imageUrl;
        tag.target = '_blank';
        tag.download = fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      };
      xhr.onerror = (err) => {
        console.log(err);
        //this.$store.dispatch("message/onDownloadFailed");
        window.open(`${assetUrl},${fileName}`, '_blank').focus();
      };
      xhr.send();
    },
  },
};
</script>

<style lang="scss">
.c-renderer {
  height: 100%;

  &__download {
    position: absolute;
    z-index: 1;
    top: var(--margin-lg);
    right: var(--margin-lg);
    width: 32px;
    height: 32px;

    border-radius: var(--border-radius-full);
    color: var(--color-primary);

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      top: -2px;
      left: -2px;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      background-color: var(--color-button-font);
      border-radius: var(--border-radius-full);
      pointer-events: none;
    }

    //@include focus();

    svg {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      //opacity: 1;
    }

    &.visible {
      opacity: 1;
    }

    &.active {
      color: var(--color-primary);
    }

    &:hover {
      color: var(--color-secondary);
    }
  }

  &__labels {
    position: absolute;
    top: var(--margin-lg);
    left: var(--margin-lg);
    display: flex;

    label {
      padding: var(--margin) var(--margin-lg);
      font-size: var(--font-size-sm);
      color: #fff;
      text-transform: capitalize;
      font-weight: 500;
      background-color: rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(4px);
      border-radius: var(--border-radius);
      user-select: none;
    }
  }
}
</style>
