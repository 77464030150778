<template>
  <div class="c-modal-block-create">
    <Modal v-model="options.active" @input="closeModal" :closable="true">
      <h2>Add Ad</h2>
      <p class="article-text">Add an ad from the Facebook Ad Library</p>
      <FormBlockCreate @onSubmit="onSubmit"></FormBlockCreate>
    </Modal>
  </div>
</template>

<script>
import Modal from './index.vue';
import FormBlockCreate from '@swipekit/components/form/form-block-create.vue';
export default {
  components: {
    Modal,
    FormBlockCreate,
  },

  data: function () {
    return {
      options: {
        active: false,
      },
    };
  },

  watch: {
    'options.active': {
      handler: function () {},
    },
    addId: function () {
      if (this.addId) {
        this.options.active = true;
      } else {
        this.options.active = false;
      }
    },
  },

  computed: {
    addId: function () {
      return; //this.$store.getters["ads/addId"];
    },
  },

  methods: {
    onSubmit: function () {
      this.options.active = false;
    },
    activate: function () {
      this.options.active = true;
    },
    closeModal: function () {
      this.$store.dispatch('ads/setAdd', null);
    },
  },

  mounted: function () {
    //this.options.active = true;
  },
};
</script>

<style lang="scss">
.c-modal-block-create {
  .modal__content {
    padding: var(--spacer);
  }
}
</style>
