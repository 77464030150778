<template>
	<div class="c-item-header">
		<Avatar :block="block" size="small">
			<ActiveStatus v-if="block.type === 'facebook'" :block="block"></ActiveStatus>
		</Avatar>

		<article>
			<p :title="title">{{ computedTitle }}</p>

			<div v-if="createdAgo">Saved {{ createdAgo }}</div>
		</article>

		<div :class="['c-item-header__buttons', { shared: shared === true }]">
			<a class="open-share-ad" href="#" v-if="!multiplayer">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M10 5.5L10.7514 4.75837C13.0959 2.41387 16.8971 2.41388 19.2416 4.75837C21.5861 7.10287 21.5861 10.9041 19.2416 13.2486L18.5 14M5.5 10L4.75837 10.7514C2.41387 13.0959 2.41388 16.8971 4.75838 19.2416C7.10287 21.5861 10.9041 21.5861 13.2486 19.2416L14 18.5M10 14L14 10"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			</a>

			<a :class="['open-dropdown', uniqueClass]" href="#" v-if="!shared && !multiplayer">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M12 5C12.5523 5 13 4.55228 13 4C13 3.44772 12.5523 3 12 3C11.4477 3 11 3.44772 11 4C11 4.55228 11.4477 5 12 5Z"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M12 21C12.5523 21 13 20.5523 13 20C13 19.4477 12.5523 19 12 19C11.4477 19 11 19.4477 11 20C11 20.5523 11.4477 21 12 21Z"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			</a>

			<span class="c-grid-item__label" v-if="labels"> 2 </span>

			<!-- <div
        v-if="user && user.type === 'ADMIN'"
        :class="['c-item-header__rating', `rating-${rating}`]"
        v-tooltip="tooltip(ratingTooltip)"
      >
        <span></span>
        <span></span>
        <span></span>
      </div> -->
		</div>
	</div>
</template>

<script>
import Avatar from '@swipekit/components/utils/avatar';
import ActiveStatus from '@swipekit/components/utils/active-status';
import { customAlphabet } from 'nanoid';
import { parseISO, formatDistanceToNow } from 'date-fns';

const nanoid = customAlphabet('1234567890abcdef', 10);

export default {
	components: {
		Avatar,
		ActiveStatus,
	},

	props: {
		labels: {
			type: Boolean,
			default: false,
		},
		block: {},
		ad: {},
		shared: {
			type: Boolean,
			default: false,
		},
		multiplayer: {
			type: Boolean,
			default: false,
		},
		isAdmin: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		user: function () {
			return this.$store.getters['users/user'];
		},
		createdAgo: function () {
			if (!this.ad) {
				return;
			}
			let createdAt = this.ad.createdAt;
			if (!createdAt) {
				return;
			}
			const savedDate = parseISO(createdAt);

			// Humanize the date
			const humanizedDate = formatDistanceToNow(savedDate, { addSuffix: true });

			return humanizedDate;
		},
		rating: function () {
			let rating = this.block.rating;
			if (!rating || rating === 0) {
				return 'na';
			}

			if (rating === 1) {
				return 1;
			}

			if (rating >= 2 && rating <= 6) {
				return 2;
			}

			if (rating >= 7) {
				return 3;
			}

			return 'na';
		},
		ratingTooltip: function () {
			let rating = this.rating;

			if (rating === 'na') {
				return `We don't have enough data about this Ad to give it a rating`;
			}
			if (rating === 1) {
				return `This Ad might not be performant`;
			}
			if (rating === 2) {
				return `This Ad might be performant`;
			}
			if (rating === 3) {
				return `This Ad might be exceptionally performant`;
			}
		},
		uniqueClass: function () {
			return `dropdown-${nanoid()}`;
		},
		title: function () {
			let block = this.block;

			if (block.title) {
				return block.title;
			}

			if (block.brand) {
				return block.brand.name;
			}

			if (block.meta && block.meta.advertiser) {
				return block.meta.advertiser.name;
			}

			if (block.type === 'webpageSelection') {
				return 'Webpage clip';
			}

			return block.type;
		},
		computedTitle: function () {
			if (this.block.type === 'upload-custom') {
				return `Manual upload`;
			}
			let max = 15;
			let title = this.title;

			if (!title) {
				return;
			}

			if (title.length > max) {
				return title.slice(0, max) + '..';
			} else {
				return title;
			}
		},
		computedTags: function () {
			let tags = this.block.tags || this.block.categories || [];

			let newTags = [];

			for (let i = 0; i < tags.length; i++) {
				if (tags[i].text) {
					newTags.push({
						text: tags[i].text,
					});
				} else {
					newTags.push({
						text: tags[i],
					});
				}
			}

			return newTags;
		},
		text: function () {
			let type = this.block.type;

			if (type === 'email') {
				return 'Email';
			}
			if (type === 'webpage') {
				return 'Webpage';
			}
			if (type === 'webpageSelection') {
				return 'Webpage section';
			}
			if (type === 'facebook') {
				return 'Facebook Ad library';
			}

			return '';
		},
	},

	methods: {
		tooltip: function (content) {
			return { content: content, offset: 10, delay: 200 };
		},
		onBoardClick: function (e) {
			this.$emit('boardClick', e.target);
		},
	},
};
</script>

<style lang="scss">
.c-item-header {
	position: relative;
	//z-index: var(--z-2);
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	max-height: 52px;
	padding: var(--margin-lg);
	padding-right: 64px;

	display: flex;
	align-items: center;

	user-select: none;

	//background-color: rgba(255, 255, 255, 0.75);
	//backdrop-filter: blur(4px);

	> article {
		display: flex;
		max-width: 100%;
		overflow: hidden;
		flex-direction: column;
		justify-content: center;
		margin-left: var(--margin-lg);
		margin-right: var(--margin);

		p {
			margin: 0;
			margin-bottom: var(--margin-sm);
			font-size: var(--font-size);
			opacity: 0.8;
			color: var(--color-font);
			line-height: 1;
		}

		div {
			display: block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			opacity: 0.7;
			font-size: var(--font-size-xs);

			i {
				display: inline-block;
				margin-right: var(--margin-sm);
				line-height: 1;
				font-style: normal;
				opacity: 0.8;
				color: var(--color-font);
				font-size: var(--font-size-xs);

				&:not(:last-child) {
					&:after {
						content: ',';
					}
				}
			}
		}
	}

	&__rating {
		width: 8px;
		height: 32px;
		margin-left: var(--spacer-sm);
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		align-items: center;
		border-radius: 4px;
		overflow: hidden;
		box-shadow: 0 0 0 2px var(--color-bg-4);

		> span {
			position: relative;
			display: block;
			width: 100%;
			height: 10.5px;
			background-color: var(--color-bg-4);
		}

		&.rating-na {
			> span {
				background-color: var(--color-bg-5);
			}
		}

		&.rating-1 {
			> span {
				&:nth-child(3) {
					background-color: var(--color-danger);
				}
			}
		}
		&.rating-2 {
			> span {
				&:nth-child(2) {
					background-color: var(--color-warning);
				}
				&:nth-child(3) {
					background-color: var(--color-warning);
				}
			}
		}
		&.rating-3 {
			> span {
				&:nth-child(1) {
					background-color: var(--color-success);
				}
				&:nth-child(2) {
					background-color: var(--color-success);
				}
				&:nth-child(3) {
					background-color: var(--color-success);
				}
			}
		}
	}

	&__buttons {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		display: flex;
		align-items: center;
		padding-right: var(--margin-lg);

		a {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: var(--border-radius-full);
			color: var(--color-font);
			opacity: 0.75;

			> svg {
				display: inline-block;
			}

			&:hover,
			&:active {
				opacity: 1;
				color: var(--color-primary);
			}

			&:focus {
				opacity: 1;
				outline: none;
			}

			&:last-child {
				margin-left: var(--margin);
			}
		}

		&.shared {
			display: none;
		}
	}

	.c-grid-item__label {
		top: 15px;
		left: initial;
		right: 55px;
	}

	@media screen and (min-width: 1400px) {
		max-height: 64px;

		.c-item-avatar {
			&.small {
				width: 43px;
				height: 43px;
				min-width: 43px;
			}
		}

		.c-active-status {
			width: 18px;
			height: 18px;
			border-width: 2px;
		}

		> article {
			p {
				font-size: var(--font-size);
			}
			span i {
				font-size: var(--font-size-sm);
			}
		}
	}

	// @media screen and (max-width: 1480px) {
	// 	& > article {
	// 		p {
	// 			font-size: var(--font-size-sm);
	// 		}
	// 	}
	// }
}
</style>
