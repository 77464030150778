<template>
	<div :class="['c-item-avatar', { small: size === 'small' }]">
		<svg v-if="block.type === 'upload-custom'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7 19C4.23858 19 2 16.7614 2 14C2 11.4673 3.88316 9.37436 6.32568 9.04508C7.13649 6.69118 9.37075 5 12 5C15.3137 5 18 7.68629 18 11C20.2091 11 22 12.7909 22 15C22 17.2091 20.2091 19 18 19H7Z"
				stroke="currentColor"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
		<template v-else>
			<img class="c-item-avatar__logo" :src="primaryImage" v-if="primaryImage" />
			<img class="c-item-avatar__secondary-logo" v-if="showSecondary && secondaryImage" :src="secondaryImage" />
		</template>
		<slot></slot>
	</div>
</template>

<script>
export default {
	props: {
		block: {},
		size: {
			type: String,
			default: '',
		},
		showSecondary: {
			type: Boolean,
			default: true,
		},
	},

	computed: {
		primaryImage: function () {
			let block = this.block;
			let assetUrl = this.assetUrl;
			if (block.brand && block.brand.logo) {
				return `${assetUrl}/${block.brand.logo}`;
			}
			if (block.type === 'facebook' && block.meta && block.meta.advertiser && block.meta.advertiser.avatar) {
				return `${assetUrl}/${block.meta.advertiser.avatar.name}`;
			}
			if (block.type === 'tiktok-top-ads') {
				return '/brands/tiktok.png';
			}
			if (block.type === 'tiktok' && block.meta && block.meta.advertiser && block.meta.advertiser.avatar) {
				return `${assetUrl}/${block.meta.advertiser.avatar.name}`;
			}
			if (block.type === 'linkedin' && block.meta && block.meta.advertiser && block.meta.advertiser.avatar) {
				return `${assetUrl}/${block.meta.advertiser.avatar.name}`;
			}
			if (block.author && block.author.assetPath) {
				return `${assetUrl}/${block.author.assetPath}`;
			}
			if (block.source && block.source.assetPath) {
				return `${assetUrl}/${block.source.assetPath}`;
			}

			return null;
		},
		secondaryImage: function () {
			if (this.block.type === 'facebook') {
				return '/brands/facebook.png';
			} else if (this.block.type === 'linkedin') {
				return '/brands/linkedin.png';
			} else if (this.block.type === 'tiktok') {
				return '/brands/tiktok.png';
			} else if (this.block.type === 'pinterest') {
				return '/brands/pinterest.svg';
			}
		},
		assetUrl: function () {
			return this.$store.getters['config/assetUrl'];
		},
	},

	methods: {},
};
</script>

<style lang="scss">
.c-item-avatar {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 44px;
	width: 44px;
	height: 44px;
	border-radius: var(--border-radius-full);
	color: var(--color-font);

	&:after {
		content: '';
		position: absolute;
		top: 1px;
		left: 1px;
		width: calc(100% - 2px);
		height: calc(100% - 2px);
		background-color: var(--color-font);
		border-radius: var(--border-radius-full);
	}

	svg {
		display: block;
		position: relative;
		z-index: 1;
		color: #fff;
	}

	&.small {
		min-width: 32px;
		width: 32px;
		height: 32px;
	}

	&__logo {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-position: center center;
		object-fit: contain;
		border-radius: 50%;
	}

	&__secondary-logo {
		position: absolute;
		z-index: 1;
		top: -3px;
		right: -3px;

		width: 50%;
		height: 50%;
		object-position: center center;
		object-fit: contain;
		border-radius: 50%;
		background-color: var(--color-button-font);
		border: var(--color-button-font) solid 1px;
	}
}
</style>
