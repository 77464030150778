var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"item",class:[
		'c-grid-item',
		_vm.view,
		_vm.type,
		{
			selected: _vm.isSelected === true,
			selectable: _vm.selection.length > 0,
			multiplayer: _vm.multiplayer === true,
			clickable: _vm.clickable === true,
		} ],style:(_vm.style),attrs:{"data-id":_vm.ad.id}},[_c('div',{staticClass:"c-grid-item__wrapper"},[_c('div',{staticClass:"c-grid-item__inner",on:{"mousedown":function($event){$event.preventDefault();return _vm.onClick.apply(null, arguments)},"touchstart":function($event){$event.preventDefault();return _vm.onClick.apply(null, arguments)}}},[_c('HeaderComponent',{attrs:{"multiplayer":_vm.multiplayer,"block":_vm.ad.block,"ad":_vm.ad,"shared":_vm.shared,"isAdmin":_vm.isAdmin,"labels":_vm.labels}}),_c('div',{staticClass:"c-grid-item__clickable",style:(_vm.clickableStyle)},[(_vm.showBulkSelect)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:("Select Ad for bulk edit"),expression:"`Select Ad for bulk edit`"}],class:['c-grid-item__icon c-grid-item__checkbox', { visible: _vm.selection.length > 0, active: _vm.isSelected === true }]},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"3","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('polyline',{attrs:{"points":"20 6 9 17 4 12"}})])]):_vm._e(),_c('Renderer',{attrs:{"shared":_vm.shared,"multiplayer":_vm.multiplayer,"standalone":_vm.standalone,"block":_vm.ad.block,"single":true}}),(_vm.labels)?_c('span',{staticClass:"c-grid-item__label"},[_vm._v(" 1 ")]):_vm._e()],1),(_vm.view === '' || _vm.view === 'all')?_c('Copy',{attrs:{"block":_vm.ad.block,"labels":_vm.labels}}):_vm._e(),(_vm.view === 'md')?_c('Preview',{attrs:{"block":_vm.ad.block}}):_vm._e(),(_vm.view === 'all')?_c('Tags',{attrs:{"ad":_vm.ad}}):_vm._e(),(_vm.view === '' || (_vm.view === 'all' && _vm.ad.block.type !== 'upload-custom'))?_c('Metadata',{attrs:{"ad":_vm.ad,"labels":_vm.labels}}):_vm._e(),(_vm.multiplayer && _vm.ad.block)?_c('Picker',{attrs:{"fbsaved":_vm.ad.block.isSaved,"blockId":_vm.ad.block.id}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }