<template>
	<div
		ref="item"
		:class="[
			'c-grid-item',
			view,
			type,
			{
				selected: isSelected === true,
				selectable: selection.length > 0,
				multiplayer: multiplayer === true,
				clickable: clickable === true,
			},
		]"
		:style="style"
		:data-id="ad.id"
	>
		<div class="c-grid-item__wrapper">
			<div class="c-grid-item__inner" @mousedown.prevent="onClick" @touchstart.prevent="onClick">
				<HeaderComponent :multiplayer="multiplayer" :block="ad.block" :ad="ad" :shared="shared" :isAdmin="isAdmin" :labels="labels"></HeaderComponent>

				<div class="c-grid-item__clickable" :style="clickableStyle">
					<span v-if="showBulkSelect" :class="['c-grid-item__icon c-grid-item__checkbox', { visible: selection.length > 0, active: isSelected === true }]" v-tooltip="`Select Ad for bulk edit`">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
							<polyline points="20 6 9 17 4 12"></polyline>
						</svg>
					</span>

					<Renderer :shared="shared" :multiplayer="multiplayer" :standalone="standalone" :block="ad.block" :single="true"></Renderer>

					<span class="c-grid-item__label" v-if="labels"> 1 </span>
				</div>

				<Copy v-if="view === '' || view === 'all'" :block="ad.block" :labels="labels"></Copy>

				<Preview v-if="view === 'md'" :block="ad.block"></Preview>

				<Tags v-if="view === 'all'" :ad="ad"></Tags>

				<Metadata v-if="view === '' || (view === 'all' && ad.block.type !== 'upload-custom')" :ad="ad" :labels="labels"> </Metadata>

				<Picker v-if="multiplayer && ad.block" :fbsaved="ad.block.isSaved" :blockId="ad.block.id"></Picker>
			</div>
		</div>
	</div>
</template>

<script>
import HeaderComponent from './header';
import ImageComponent from './image';
import Metadata from './metadata';
import Preview from './preview';
import Copy from './copy';
import Tags from './tags';

import Renderer from '@swipekit/components/renderer/index.vue';

import Picker from '@swipekit/components/picker';

export default {
	components: {
		HeaderComponent,
		ImageComponent,
		Metadata,
		Renderer,
		Picker,
		Preview,
		Copy,
		Tags,
	},

	props: {
		hideAdModal: {
			type: Boolean,
			default: false,
		},
		labels: {
			type: Boolean,
			default: false,
		},
		ad: {},
		standalone: {
			type: Boolean,
			default: false,
		},
		shared: {
			type: Boolean,
			default: false,
		},
		multiplayer: {
			type: Boolean,
			default: false,
		},
		view: {
			type: String,
			default: function () {
				return 'all';
			},
		},
	},

	computed: {
		type: function () {
			if (this.ad && this.ad.block && this.ad.block.type) {
				return this.ad.block.type;
			}
		},
		clickable: function () {
			if (this.multiplayer) {
				return false;
			}
			if (this.shared) {
				return false;
			}

			return true;
		},
		showBulkSelect: function () {
			if (this.multiplayer) {
				return false;
			}

			if (this.shared) {
				return false;
			}

			if (this.standalone) {
				return false;
			}

			return true;
		},
		user: function () {
			return this.$store.getters['users/user'];
		},
		isAdmin: function () {
			return this.$store.getters['users/isAdmin'];
		},
		options: function () {
			return this.$store.getters['app/options'];
		},
		style: function () {
			let gridSize = this.options.gridSize;
			let style = ''; //`width:${gridSize}px`;

			return style;
		},
		clickableStyle: function () {
			let block = this.ad.block;
			if (block.assetType === 'text') {
				// don't show padding at all
				return `padding-bottom:0`;
			}

			let assetRatio = this.getRatio(block);

			let padding = assetRatio * 100;
			let str = `padding-bottom:${padding}%;`;

			return str;
		},
		selection: function () {
			return this.$store.getters['ads/selection'];
		},
		isSelected: function () {
			let selection = this.selection;

			if (!this.ad) {
				return;
			}

			if (!this.ad.id) {
				return;
			}

			if (selection.includes(this.ad.id)) {
				return true;
			}
			return false;
		},
		assetUrl: function () {
			return this.$store.getters['config/assetUrl'];
		},
		workspace: function () {
			return this.$store.getters['workspaces/all'];
		},
	},

	methods: {
		getRatio: function (block) {
			let assetRatio = 1;

			if (block.assets && block.assets.length) {
				let asset = block.assets[0];

				if (asset.ratio) {
					assetRatio = asset.ratio;
					return assetRatio;
				}
			}

			if (block.assetRatio) {
				assetRatio = block.assetRatio;
			}

			return assetRatio;
		},
		onSubmit: function (e) {
			this.$emit('onSubmit', e);
		},
		onGotoComment: function () {
			this.$emit('commentClick', this.ad.id, this.$refs.item);
		},
		downloadVideo: function (url) {
			var xhr = new XMLHttpRequest();
			xhr.open('GET', url, true);
			xhr.responseType = 'blob';
			xhr.onload = function () {
				var urlCreator = window.URL || window.webkitURL;
				var imageUrl = urlCreator.createObjectURL(this.response);
				var tag = document.createElement('a');
				tag.href = imageUrl;
				tag.target = '_blank';
				tag.download = 'sample.mp4';
				document.body.appendChild(tag);
				tag.click();
				document.body.removeChild(tag);
			};
			xhr.onerror = (err) => {
				alert('Failed to download picture');
			};
			xhr.send();
		},
		onGotoSource: function () {
			if (this.ad.block.source && this.ad.block.source.url) {
				window.open(this.ad.block.source.url, '_newtab');
			}
		},
		onDownload: function (url, fileName) {
			this.downloadVideo(url);
			this.$store.dispatch('events/sendEvent', {
				name: 'download',
			});
		},
		onClick: function (e) {
			let shared = this.shared;
			if (this.selection.length > 0) {
				this.toggleSelection();
				return;
			}

			let clickPrevent = '.click-prevent';
			let condition = e.target.closest(clickPrevent);
			if (condition) {
				return;
			}

			let download = `.c-renderer__download`;

			condition = e.target.closest(download);
			if (condition) {
				return;
			}

			let yt = 'lite-youtube';

			condition = e.target.closest(yt);
			if (condition) {
				return;
			}

			let copy = `.btn-copy`;
			condition = e.target.closest(copy);
			if (condition) {
				return;
			}

			let formSave = `.c-form-save-block .btn-primary`;

			condition = e.target.closest(formSave);
			if (condition) {
				return;
			}

			let cta = '.c-cta';

			condition = e.target.closest(cta);
			if (condition) {
				if (this.ad.block.meta.ctaLink) {
					window.open(this.ad.block.meta.ctaLink, '_blank').focus();
					return;
				}
			}

			let carousel = `.VueCarousel`;

			condition = e.target.closest(carousel);

			if (condition) {
				return;
			}

			let checkbox = `.c-grid-item__checkbox`;

			condition = e.target.closest(checkbox);
			if (condition) {
				this.toggleSelection();

				return;
			}

			let remove = `.c-grid-item__remove`;

			condition = e.target.closest(remove);
			if (condition) {
				this.removeAd();

				return;
			}

			let link = `.c-ad-link__link`;
			condition = e.target.closest(link);
			if (condition) {
				window.open(e.target.href, '_blank').focus();
				return;
			}

			let comment = `.c-item-footer__comments`;

			condition = e.target.closest(comment);
			if (condition) {
				return;
			}

			let share = `.c-grid-item__share`;

			condition = e.target.closest(share);
			if (condition) {
				window.open('https://swipekit.app', '_blank').focus();
				return;
			}

			let shareAd = `.open-share-ad`;

			condition = e.target.closest(shareAd);
			if (condition) {
				let temp = JSON.parse(JSON.stringify(this.ad));
				this.$store.dispatch('app/setAdShare', temp);
				return;
			}

			let dropdownClick = `.open-dropdown`;

			condition = e.target.closest(dropdownClick);
			if (condition) {
				let tempClass = ``;
				condition.classList.forEach((e) => {
					if (e.includes('dropdown-')) {
						tempClass = e;
					}
				});
				this.$emit('onDropdownClick', {
					tempClass: tempClass,
					id: this.ad.id,
				});
				return;
			}

			e.preventDefault();

			if (this.hideAdModal) {
				return;
			}

			// if (e.target.closest(".c-renderer")) {
			//   return;
			// }
			if (!shared) {
				this.$store.dispatch('ads/setView', this.ad.id);
			}
		},
		toggleSelection: function () {
			let selection = this.selection;
			if (selection.includes(this.ad.id)) {
				this.$store.dispatch('ads/removeSelection', this.ad.id);
			} else {
				this.$store.dispatch('ads/addSelection', this.ad.id);
			}
		},
		removeAd: function () {
			this.$emit('onRemoveAd', this.ad.id);
		},
		copy: function () {
			let ad = JSON.parse(JSON.stringify(this.ad));
			delete ad.id;
			const str = JSON.stringify(ad);
			navigator.clipboard.writeText(str);
		},
	},

	mounted: function () {},
};
</script>

<style lang="scss">
.c-grid-item {
	width: var(--item-width);
	padding: var(--item-padding);
	height: auto;

	&__wrapper {
		position: relative;
	}

	// &__comment {
	// 	position: absolute;
	// 	top: calc(100% - 1px);
	// 	left: 0;
	// 	padding: var(--margin-sm) var(--margin-lg);
	// 	background-color: var(--color-bg-1);
	// 	border-bottom-left-radius: 10px;
	// 	border-bottom-right-radius: 10px;
	// 	white-space: nowrap;
	// 	font-size: var(--font-size-xs);
	// 	font-weight: 500;
	// 	box-shadow: 0px 1px 0.6px rgba(0, 0, 0, 0.085), 0px 3px 6px rgba(0, 0, 0, 0.07);
	// }

	&__inner {
		display: flex;
		flex-direction: column;

		cursor: pointer;

		background-color: var(--color-bg-1);

		transition: box-shadow var(--transition-time-sm) linear;

		box-shadow: var(--item-box-shadow);
		border-radius: var(--item-border-radius);
		user-select: none;
	}

	&.upload-custom {
		.c-grid-item__clickable {
			border-bottom-left-radius: var(--item-border-radius);
			border-bottom-right-radius: var(--item-border-radius);
		}
	}

	&.sm {
		.c-grid-item__clickable {
			border-bottom-left-radius: var(--item-border-radius);
			border-bottom-right-radius: var(--item-border-radius);
		}

		&.multiplayer {
			.c-grid-item__clickable {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
	}

	&.md {
		.c-item-preview {
			border-bottom-left-radius: var(--item-border-radius);
			border-bottom-right-radius: var(--item-border-radius);
		}

		&.tiktok-library,
		&.tiktok,
		&.tiktok-top-ads {
			.c-grid-item__clickable {
				border-bottom-left-radius: var(--item-border-radius);
				border-bottom-right-radius: var(--item-border-radius);
			}
		}
	}

	&.all {
		.c-item-metadata {
			overflow: hidden;
			border-bottom-left-radius: var(--item-border-radius);
			border-bottom-right-radius: var(--item-border-radius);
		}
	}

	&.selectable {
		.c-grid-item__inner {
			cursor: pointer;
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: hsla(var(--p), 5%, 60%, 0.15);
				transition: background-color var(--transition-time) linear;
				border-radius: var(--item-border-radius);
			}

			&:hover {
				&:after {
					background-color: hsla(var(--p), 60%, 60%, 0.35);
				}
			}
		}
	}

	&.selected {
		.c-grid-item__inner {
			box-shadow: 0 0 0 3px var(--color-primary), 0px 0.3px 0.8px rgba(0, 0, 0, 0.085), 0px 2px 6px rgba(0, 0, 0, 0.09);

			&:after {
				background-color: hsla(var(--p), 60%, 60%, 0.35);
			}
		}
	}

	&.multiplayer {
		.c-grid-item__inner {
			cursor: initial;
		}
	}

	&.clickable {
		.c-grid-item__inner {
			&:hover,
			&:active {
				box-shadow: 0 0 0 3px var(--color-primary), 0px 0.3px 0.8px rgba(0, 0, 0, 0.085), 0px 2px 6px rgba(0, 0, 0, 0.09);
			}
		}
	}

	&__share {
		padding: var(--margin) var(--margin-sm);
		color: var(--color-font);
		opacity: 0.75;
		text-align: center;
		font-weight: 400;
		font-size: var(--font-size-sm);
		text-decoration: none;
	}

	&__clickable {
		position: relative;
		overflow: hidden;
		background-color: var(--color-bg-5);

		transition: box-shadow var(--transition-time) linear;

		.c-renderer {
			width: 100%;
			height: 100%;
			position: absolute;

			.r-facebook .VueCarousel {
				&-slide {
					img {
						display: block;
						width: 100%;
						height: initial;
					}
				}
			}

			&__download {
				opacity: 0;
			}
		}

		.c-item-footer {
			min-height: 48px;
		}

		&:focus,
		&:hover {
			outline: none;

			.c-grid-item__checkbox {
				opacity: 1;
			}

			.c-grid-item__remove {
				opacity: 1;
			}

			.c-renderer {
				&__download {
					opacity: 1;
				}
			}
		}
	}

	&__icon {
		position: absolute;
		top: var(--margin-lg);
		left: var(--margin-lg);
		width: 32px;
		height: 32px;
		user-select: none;

		border-radius: var(--border-radius-full);
		opacity: 0;
		color: var(--color-button-font);

		display: flex;
		justify-content: center;
		align-items: center;

		&:before {
			content: '';
			position: absolute;
			z-index: 1;
			top: -2px;
			left: -2px;
			width: calc(100% + 4px);
			height: calc(100% + 4px);
			background-color: var(--color-primary);
			border-radius: var(--border-radius-full);
			border: #fff solid 2px;
		}

		svg {
			position: relative;
			z-index: 1;
			width: 60%;
			height: 60%;
			//opacity: 1;
		}

		&.visible {
			opacity: 1;
		}

		&.active {
			color: var(--color-primary);

			&:before {
				background-color: var(--color-button-font);
			}
		}
	}

	&__label {
		position: absolute;
		z-index: 99;
		top: 48px;
		left: 12px;
		width: 24px;
		height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--color-primary);
		color: var(--color-button-font) !important;
		text-decoration: none !important;
		line-height: 1;
		font-weight: 500;
		font-size: var(--font-size-xs);
		font-feature-settings: 'case' 0, 'dlig' 0, 'frac' 0, 'dnom' 0, 'numr' 0, 'subs' 0, 'sups' 0, 'tnum', 'zero' 0, 'ss01', 'ss02' 0, 'ss03' 0, 'ss04', 'ss05' 0, 'ss06' 0, 'ss07' 0, 'ss08' 0, 'cv01' 0,
			'cv02' 0, 'cv03' 0, 'cv04' 0, 'cv05' 0, 'cv06' 0, 'cv07' 0, 'cv08' 0, 'cv09' 0, 'cv10' 0, 'cv11' 0, 'cv12' 0, 'cv13' 0, 'cpsp' 0, 'c2sc' 0, 'salt' 0, 'aalt' 0, 'calt', 'ccmp', 'locl', 'kern';

		border-radius: 50%;
		box-shadow: 0 0 0 4px hsla(var(--p), 50%, 60%, 0.5);
	}

	&__remove {
		top: calc(var(--margin-lg) * 2 + 32px);
		left: var(--margin-lg);

		&:before {
			background-color: var(--color-danger);
		}

		&.active {
			color: var(--color-danger);
		}
	}
}
</style>
