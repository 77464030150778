<template>
	<div class="c-upload">
		<div class="c-upload__droppable" :class="{ dragging: isDragging }" @dragover.prevent="onDragOver" @dragenter.prevent="onDragEnter" @dragleave.prevent="onDragLeave" @drop.prevent="onDrop">
			<article>
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M12 19V12M12 12L14.5 14.5M12 12L9.5 14.5M8 19H7C4.23858 19 2 16.7614 2 14C2 11.4673 3.88316 9.37436 6.32568 9.04508C7.13649 6.69118 9.37075 5 12 5C15.3137 5 18 7.68629 18 11C20.2091 11 22 12.7909 22 15C22 17.2091 20.2091 19 18 19H16"
						stroke="currentColor"
						stroke-width="1"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
				<br />
				<p v-if="!files.length">Drag & drop your files here or click to select.</p>
				<button class="btn btn-box btn-primary" @click="triggerFileInput">Select Files</button>
				<button v-if="files && files.length > 0" class="btn btn-box" @click="reset">Reset</button>
			</article>

			<input type="file" ref="fileInput" multiple :accept="acceptedFormats" @change="onFileSelect" hidden />

			<div v-if="files.length" class="c-upload__droppable__grid">
				<div v-for="(file, index) in files" :key="index" class="c-upload__item">
					<img v-if="isImage(file)" :src="file.preview" alt="File Preview" class="c-upload__image" />
					<video v-if="isVideo(file)" :src="file.preview" controls class="c-upload__video"></video>
					<span class="c-upload__name">{{ file.name }}</span>
				</div>
			</div>
		</div>
		<div class="c-upload__footer">
			<button v-if="files && files.length > 0" :disabled="files.length === 0 ? true : false" class="btn btn-lg btn-primary btn-box" @click="onUpload">
				<span v-if="processing" class="c-spinner"> </span>
				<span>Upload files </span>
			</button>
		</div>
		<div class="c-upload__progress">
			<span :style="progressStyle"></span>
		</div>
	</div>
</template>

<script>
import api from '@swipekit/lib/api';

export default {
	data() {
		return {
			isDragging: false,
			acceptedFormats: '.jpg,.jpeg,.png,.mp4',
			files: [],
			progress: 0,
			processing: false,
		};
	},

	computed: {
		progressStyle: function () {
			let obj = {
				width: `${this.progress * 100}%`,
			};

			return obj;
		},
	},

	methods: {
		onDragOver(event) {
			event.dataTransfer.dropEffect = 'copy';
		},
		onDragEnter() {
			this.isDragging = true;
		},
		onDragLeave() {
			this.isDragging = false;
		},
		onDrop(event) {
			this.isDragging = false;

			const droppedFiles = Array.from(event.dataTransfer.files).filter((file) => this.validateFile(file));

			if (droppedFiles.length) {
				this.prepareFiles(droppedFiles);
			}
		},
		onFileSelect(event) {
			const selectedFiles = Array.from(event.target.files).filter((file) => this.validateFile(file));

			if (selectedFiles.length) {
				this.prepareFiles(selectedFiles);
			}
		},
		validateFile(file) {
			const validFormats = this.acceptedFormats.split(',');
			return validFormats.includes(`.${file.type.split('/')[1]}`);
		},
		prepareFiles(fileList) {
			const preparedFiles = fileList.map((file) => {
				const filePreview = URL.createObjectURL(file);
				return { name: file.name, preview: filePreview, type: file.type, raw: file };
			});
			this.files.push(...preparedFiles);
		},
		triggerFileInput() {
			this.$refs.fileInput.click();
		},
		isImage(file) {
			return file.type.startsWith('image/');
		},
		isVideo(file) {
			return file.type.startsWith('video/');
		},
		async onUpload() {
			this.processing = true;

			this.$store.dispatch('message/onUploadStart');

			const formData = new FormData();

			for (let i = 0; i < this.files.length; i++) {
				//console.log(this.files[i]);
				let condition = await this.uploadFile(this.files[i]);

				this.progress = i / this.files.length;
			}

			this.progress = 1;

			this.processing = false;

			this.files = [];

			this.$store.dispatch('message/onUploadComplete');
		},
		async uploadFile(file) {
			const formData = new FormData();
			formData.append('file', file.raw);
			let success = false;

			try {
				// Send POST request to upload the images
				const response = await api.ads.upload(formData);
				success = true;
				//this.sent = true;
			} catch (err) {
				console.log(err);
				//this.error = true;
			}

			return success;
		},
		reset: function () {
			this.files = [];
		},
	},
	beforeDestroy() {
		// Revoke object URLs to avoid memory leaks
		this.files.forEach((file) => URL.revokeObjectURL(file.preview));
	},
};
</script>

<style lang="scss" scoped>
.c-upload {
	&__droppable {
		border: 2px dashed #ccc;
		padding: 20px;
		text-align: center;
		transition: background-color 0.3s ease;
		border-radius: var(--border-radius-lg);
		height: 400px;
		overflow-y: auto;
		background-color: var(--color-bg-1);
		transition: all 100ms linear;

		&.dragging {
			background-color: hsl(var(--p), 50%, 90%);
		}

		&__grid {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(150px, 150px));
			gap: 16px;
			margin-top: 20px;
		}

		article {
			svg {
				width: 64px;
				height: 64px;
			}

			.btn {
				&:not(:first-child) {
					margin-left: var(--margin);
				}
			}
		}
	}

	&__item {
		text-align: center;
		padding: var(--margin);
		box-shadow: var(--box-shadow);
		border-radius: var(--border-radius);

		span {
			display: block;
			font-size: var(--font-size-xs);
			padding: var(--margin) var(--margin-lg);
		}
	}

	&__image,
	&__video {
		max-width: 100%;
		height: auto;
		border-radius: 4px;
	}

	&__name {
		font-size: 14px;
		color: #333;
		word-break: break-word;
	}

	&__footer {
		padding: var(--margin-lg);
		display: flex;
		justify-content: center;

		.c-spinner {
			margin-right: var(--margin);
		}
	}

	&__progress {
		width: 100%;
		height: 4px;
		border-radius: 4px;
		background-color: var(--color-bg-1);
		overflow: hidden;
		transition: all 100ms linear;

		> span {
			display: block;
			height: 100%;
			background-color: var(--color-primary);
		}
	}
}
</style>
