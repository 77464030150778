<template>
	<div class="c-modal-multiplayer-tutorial">
		<Modal v-model="options.active" @input="closeModal" :closable="true">
			<template v-slot:title>
				<article class="c-modal-multiplayer-tutorial__header">
					<span class="c-byline"> Welcome to </span>
					<h2>
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" x="256" y="256" class="iconify iconify--ion" viewBox="0 0 512 512">
							<path
								fill="currentColor"
								d="M483.13 245.38C461.92 149.49 430 98.31 382.65 84.33A107.13 107.13 0 0 0 352 80c-13.71 0-25.65 3.34-38.28 6.88C298.5 91.15 281.21 96 256 96s-42.51-4.84-57.76-9.11C185.6 83.34 173.67 80 160 80a115.74 115.74 0 0 0-31.73 4.32c-47.1 13.92-79 65.08-100.52 161C4.61 348.54 16 413.71 59.69 428.83a56.62 56.62 0 0 0 18.64 3.22c29.93 0 53.93-24.93 70.33-45.34c18.53-23.1 40.22-34.82 107.34-34.82c59.95 0 84.76 8.13 106.19 34.82c13.47 16.78 26.2 28.52 38.9 35.91c16.89 9.82 33.77 12 50.16 6.37c25.82-8.81 40.62-32.1 44-69.24c2.57-28.48-1.39-65.89-12.12-114.37ZM208 240h-32v32a16 16 0 0 1-32 0v-32h-32a16 16 0 0 1 0-32h32v-32a16 16 0 0 1 32 0v32h32a16 16 0 0 1 0 32Zm84 4a20 20 0 1 1 20-20a20 20 0 0 1-20 20Zm44 44a20 20 0 1 1 20-19.95A20 20 0 0 1 336 288Zm0-88a20 20 0 1 1 20-20a20 20 0 0 1-20 20Zm44 44a20 20 0 1 1 20-20a20 20 0 0 1-20 20Z"
							></path>
						</svg>
						Multiplayer
					</h2>
				</article>
				<section>
					<article>
						<p>Multiplayer is a collection of all Ads saved by Swipekit users.</p>
					</article>
					<!-- <video autoplay="true" loop="true">
						<source type="video/mp4" src="/videos/multiplayer1.mp4" />
					</video> -->
				</section>
				<section>
					<article>
						<p>Find Winning Ads quickly by using the Ad Active days sorting option to show longest running Ads.</p>
					</article>
					<img src="/images/multiplayer.jpg" />
				</section>
				<section>
					<article>
						<p>Use The Ad Reach sorting option to find the Ads with the biggest reach(This will show Ads on the Meta platform which have ran in the EU)</p>
					</article>
					<img src="/images/multiplayer.jpg" />
				</section>
			</template>
		</Modal>
	</div>
</template>

<script>
import Modal from '@swipekit/components/modal';
import FormBoardUpdate from '@swipekit/components/form/form-board-update.vue';

export default {
	components: {
		Modal,
		FormBoardUpdate,
	},

	data: function () {
		return {
			options: {
				name: 'modal-multiplayer-tutorial',
				active: false,
			},
		};
	},

	watch: {
		modals: {
			handler: function () {
				if (this.modals && this.modals.multiplayer) {
					this.options.active = true;
					console.log('showmodal');
				} else {
					this.options.active = false;
				}
			},
			deep: true,
		},
	},

	computed: {
		modals: function () {
			let modals = this.$store.getters['app/modals'];
			return modals;
		},
	},

	methods: {
		open: function () {
			this.options.active = true;
		},
		closeModal: function () {
			this.options.active = false;
		},
	},

	mounted: function () {},
};
</script>

<style lang="scss">
.c-modal-multiplayer-tutorial {
	.vfm {
		.vfm__overlay {
			opacity: 0.8;
		}
		.modal-content {
			width: 900px;
		}
	}

	&__header {
		text-align: center;
		user-select: none;
		margin-bottom: var(--spacer-lg);

		h2 {
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: 600 !important;
			font-family: var(--font-family-heading) !important;
		}

		svg {
			width: 32px;
			height: 32px;
			margin-right: var(--margin-lg);
		}

		.c-byline {
			display: block;
			font-weight: 500;
			text-transform: uppercase;
			margin-bottom: var(--margin-lg);
			letter-spacing: 0.5px;
		}
	}

	section {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: var(--margin-lg);
		margin-top: var(--spacer-sm);

		> article {
			font-size: var(--font-size-lg);
			text-align: left;
		}

		> img,
		video {
			width: 100%;
			height: auto;
			border: #fff solid 4px;
			border-radius: var(--border-radius);
		}
	}
}
</style>
