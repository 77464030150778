<template>
	<div class="c-sidebar__container">
		<div :class="['c-sidebar', { active: showSidebar }]">
			<div class="c-sidebar__header">
				<h1>Swipekit</h1>
			</div>
			<div class="c-sidebar__buttons">
				<a class="c-sidebar__item" @click.prevent="addBoard" href="#">
					<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M6 2.75C5.58579 2.75 5.25 3.08579 5.25 3.5C5.25 3.91421 5.58579 4.25 6 4.25H18C18.4142 4.25 18.75 3.91421 18.75 3.5C18.75 3.08579 18.4142 2.75 18 2.75H6ZM3.25 6C3.25 5.58579 3.58579 5.25 4 5.25H20C20.4142 5.25 20.75 5.58579 20.75 6C20.75 6.41421 20.4142 6.75 20 6.75H4C3.58579 6.75 3.25 6.41421 3.25 6ZM1 10C1 8.89543 1.89543 8 3 8H21C22.1046 8 23 8.89543 23 10V22C23 23.1046 22.1046 24 21 24H3C1.89543 24 1 23.1046 1 22V10Z"
							fill="currentColor"
						/>
					</svg>
					<span> Add Board </span>
				</a>
				<a class="c-sidebar__item" @click.prevent="uploadAd" href="#">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M20 12.75V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V12.75M12 4V15.25M12 4L16.5 8.5M12 4L7.5 8.5"
							stroke="currentColor"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>

					<span> Upload Ad </span>
				</a>
			</div>
			<svg @click="close" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM8.53033 7.46967C8.23744 7.17678 7.76256 7.17678 7.46967 7.46967C7.17678 7.76256 7.17678 8.23744 7.46967 8.53033L10.9393 12L7.46967 15.4697C7.17678 15.7626 7.17678 16.2374 7.46967 16.5303C7.76256 16.8232 8.23744 16.8232 8.53033 16.5303L12 13.0607L15.4697 16.5303C15.7626 16.8232 16.2374 16.8232 16.5303 16.5303C16.8232 16.2374 16.8232 15.7626 16.5303 15.4697L13.0607 12L16.5303 8.53033C16.8232 8.23744 16.8232 7.76256 16.5303 7.46967C16.2374 7.17678 15.7626 7.17678 15.4697 7.46967L12 10.9393L8.53033 7.46967Z"
					fill="currentColor"
				/>
			</svg>
			<div class="c-sidebar__side">
				<p>Boards</p>
			</div>
			<div class="c-sidebar__body">
				<BoardSelect></BoardSelect>
			</div>
		</div>
	</div>
</template>

<script>
import FormBulkEdit from '@swipekit/components/form/form-bulk-edit';
import InputButtonChoice from '@swipekit/components/form/input-button-choice';
import BoardSelect from '@swipekit/components/board-select';

export default {
	components: {
		FormBulkEdit,
		InputButtonChoice,
		BoardSelect,
	},

	computed: {
		showSidebar: function () {
			return this.$store.getters['app/showSidebar'];
		},
		selection: function () {
			return this.$store.getters['ads/selection'];
		},
		countString: function () {
			let selection = this.selection;

			let str = `${selection.length} ads`;

			if (selection.length === 1) {
				str = `${selection.length} ad`;
			}

			return str;
		},
		slug: function () {
			return this.$route.params.slug;
		},
		board: function () {
			if (!this.slug) {
				return;
			}
			return this.$store.getters['boards/boardBySlug'](this.slug);
		},
	},

	methods: {
		close: function () {
			this.$store.dispatch('app/toggleSidebar');
		},
		addBoard: function () {
			this.$store.dispatch('boards/setAdd', 1);
		},
		uploadAd: function () {
			this.$store.dispatch('ads/setAdd', 1);
		},
	},
};
</script>

<style lang="scss">
.c-sidebar {
	--color-font: #ededed;
	--sidebar-gap: 64px;
	position: fixed;
	top: 0;
	left: 0;
	width: 300px;
	height: 100%;
	z-index: 101;
	background-color: hsla(var(--p), 12%, 8%, 0.95);
	backdrop-filter: blur(8px);

	box-shadow: 0px 2.2px 5.3px rgba(0, 0, 0, 0.065), 0px 7.4px 17.9px rgba(0, 0, 0, 0.095), 0px 33px 80px rgba(0, 0, 0, 0.16);

	transition: all var(--transition-time) cubic-bezier(0.215, 0.61, 0.355, 1);

	transform: translateX(-4px);

	opacity: 0;
	pointer-events: none;

	> svg {
		color: #ededed;
	}

	&.active {
		transform: translateX(0);
		opacity: 1;
		pointer-events: all;
	}

	> svg {
		position: absolute;
		top: var(--margin-lg);
		right: var(--margin-lg);
		cursor: pointer;
	}

	&__header {
		padding: var(--margin-lg);

		h1 {
			margin: 0;
			font-size: 20px;
			font-weight: 600;
			color: #ededed;
			line-height: 1.5;
		}
	}

	&__side {
		padding: var(--margin-lg) var(--spacer-sm);
		padding-bottom: var(--margin);
		padding-top: 0;

		p {
			margin: 0;
			color: #ededed;
		}
	}

	&__body {
		padding: var(--margin-lg);
		padding-top: 0;
		height: calc(100% - 44px);
		overflow: auto;
	}

	&__buttons {
		padding: var(--margin-lg);
		padding-top: 0;

		a {
			width: 100%;
			position: relative;
			display: flex;
			align-items: center;
			padding: var(--margin);
			font-size: var(--font-size-sm);
			text-decoration: none !important;
			border-radius: 6px;
			color: var(--color-font);
			transition: all 120ms ease-out;
			background-color: var(--color-bg-97);

			&:not(:last-child) {
				margin-bottom: var(--margin);
			}

			svg {
				width: 24px;
				height: 24px;
			}

			span {
				padding: 0 var(--margin);
			}

			&:hover,
			&:active {
				background-color: var(--color-primary);
				color: var(--color-font);
			}
		}
	}

	.c-board-select {
		&__item {
		}

		&__counter {
			color: #000;
		}
	}

	@include media-breakpoint-down(sm) {
		right: var(--spacer);
		padding-top: 54px;
		width: 100%;
		border-top-right-radius: var(--border-radius-lg);
		border-bottom-right-radius: var(--border-radius-lg);
	}
}
</style>
